.Profile_menu {
    position: fixed;
    top: 11vh;
    right: 10rem;
    width: 180px;
    height: auto;
    opacity: 1;
    z-index: 100;
    /* padding: 1rem 1.5rem; */
    background:  rgba(0, 0, 0, 0.301);
    backdrop-filter: blur(1rem);
    border-radius: 0.5rem;
    border: 1px dashed rgba(255,255,255,0.5);
    display: grid;
    align-items: center;
}

.Profile_menu_list {
    display: grid;
    align-items: center;
    gap: 0.1rem;
}
.Profile_menu_list .Profilemenu_item {
    color: #fff;
    padding: 0.7rem 1rem;
    cursor: pointer;
    border-bottom: 1px dashed rgba(255,255,255,0.3);
    font-size: 18px;
}
.Profile_menu_list .Profilemenu_item:nth-child(1) {
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    background:  rgba(0, 0, 0, 0.301);
}
.Profile_menu_list .Profilemenu_item:nth-child(1):hover {
    background:  #fff;
    color: #000;
}
.Profile_menu_list .Profilemenu_item:last-child {
    border-radius: 0rem 0rem 0.5rem 0.5rem;
}
.Profile_menu_list .Profilemenu_item:hover {
    background: #fff;
    color: #000;
}