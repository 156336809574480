.custom-select {
    /* appearance: auto; */
    width: 100%;
    height: 45px;
    border-radius: 50px !important;
    border-color: var(--primary-color4);
    background-color: transparent;
    /* color: var(--primary-color8); */
    color: white !important;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
    padding: 12px 20px;
  }
  
  .ql-toolbar.ql-snow {
    border: 1px solid #262D3B !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
  
  .ql-container.ql-snow {
    border: 1px solid #262D3B !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;  
  }

  .white-calendar::-webkit-calendar-picker-indicator {
    filter: invert(1); /* Inverts the calendar icon color to make it white */
  }
  
  
  /* .ql-snow .ql-picker {
    color: white !important;
  } */
  
  /* .ql-formats .ql-clean{
    color: white !important;
  } */
  
  /* .ql-snow.ql-toolbar button svg, .ql-snow .ql-toolbar button svg {
    background-color: white !important;
  } */
  