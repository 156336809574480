/* Pagination container */
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 20px 0;
  justify-content: center;
  align-items: center;
}

/* Pagination item (page number) */
.page-item {
  margin: 0 5px;
}

/* Pagination link (page number button) */
.page-link {
  display: inline-block;
  padding: 8px 12px;
  background-color: #3498db;
  color: #fff;
  border: 1px solid #3498db;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Hover effect for pagination links */
.page-link:hover {
  background-color: #2980b9;
}

/* Active page */
.page-item.active .page-link {
  background-color: #2980b9;
  border-color: #2980b9;
}

/* Disabled page */
.page-item.disabled .page-link {
  background-color: #ccc;
  color: #666;
  border-color: #ccc;
  cursor: not-allowed;
}
