.newShadow {
    box-shadow: 0 0 40px rgba(68, 130, 197, 0.8);
    padding: 20px; 
    background-color: #1F1F2C;
  }

  
.sub-btn{
    height: 45px; 
    font-size: 20px;
    border-radius:7px;
    background-color: transparent;
    border: 1px solid #3a3649;
}

.sub-activebtn{
    height: 45px; 
    font-size: 20px;
    background-color:#fff;
    color: black;
    border-radius:7px
}

.sub-btn:hover{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.342);
}

.sub-activebtn:hover{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.342);
}