.product-img{
    height:717px;
    width:682px;
}

.content span{
    color: #fff !important;
}
.content strong{
    color: #fff !important;
}


@media only screen and (max-width: 767px) {
    .product-img{
        height:517px;
        width:682px;
    }
  }

  @media only screen and (max-width: 500px) {
    .product-img{
        height:317px;
        width:682px;
    }
  }